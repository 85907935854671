import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { firebaseConfig } from '../config';

// TODO: does this really need to be here?
import axios from '../utils/axios';

// ----------------------------------------------------------------------

const ROOT_ADMIN_ROLE = '2b4ed3f110b248e0aa9f71f9c7ab7843'; 

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  token: null,
  user: null
};

const reducer = (state, action) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  loginWithGoogle: () => Promise.resolve(),
  loginWithFaceBook: () => Promise.resolve(),
  loginWithTwitter: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [profile, setProfile] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(
    () => {
      let unsubscriber;

      if(dispatch){
        unsubscriber = firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            axios.get(`${process.env.REACT_APP_API}/users.get`)
            .then(response => {
               setProfile(response.data);
               dispatch({ type: 'INITIALISE', payload: { isAuthenticated: true }});
            })
            .catch((error) => {
              console.error('Error:', error);
            });
          } else {
            dispatch({ type: 'INITIALISE', payload: { isAuthenticated: false, token: null, user: null }});
          }
        });
      }
      return unsubscriber;
    },
    [dispatch]
  );

const login = (email, password) => firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        axios.post(`${process.env.REACT_APP_API}/users.signin`).then((response)=> {
          setProfile(response.data);             
        });
      }); 

  const loginWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const loginWithFaceBook = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const loginWithTwitter = () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const register = (email, password, firstName, lastName) => 
     firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(() => {          
        axios.post(`${process.env.REACT_APP_API}/users.signup`, { firstName, lastName, email } )
        .then((response) => {
          setProfile(response.data);
        });         
      });  

  const logout = async () => {
    await firebase.auth().signOut();
  };

  const resetPassword = async (email) => {  
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const setNewPassword = async (newPassword, code) => {  
    await firebase.auth().confirmPasswordReset(code, newPassword);
  };

  const auth = { ...state.user };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          id: auth.uid,
          email: auth.email,
          photoURL: auth.photoURL || profile?.photoURL,
          displayName: profile?.fullname || 'Loading...',
          isRootAdmin: profile?.roleIds || profile?.roleIds?.inculdes(ROOT_ADMIN_ROLE),
          companyId: profile?.companyId || '',
          companyName: profile?.companyName || '',
          phoneNumber: auth.phoneNumber || profile?.phoneNumber || '',
          country: profile?.country || '',
          accessList: profile?.accessList || '',
        },
        login,
        register,
        loginWithGoogle,
        loginWithFaceBook,
        loginWithTwitter,
        logout,
        resetPassword,
        setNewPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
