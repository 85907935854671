import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
/* import MainLayout from '../layouts/main'; */
import DashboardLayout from '../layouts/dashboard';
/* import LogoOnlyLayout from '../layouts/LogoOnlyLayout'; */
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import AccessBasedGuard from '../guards/AccessBasedGuard'; 
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'set-new-password', element: <SetNewPassword /> },    
        { path: 'verify', element: <VerifyCode /> }
      ]
    },
    { path: 'shared/projects/:shareId', element: <ProjectShare /> },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'grid', element: <MainGrid /> },      
        { path: 'grid/:id', element: <MainGrid /> },        
        { path: 'analytics', element: <GeneralAnalytics /> }, 
        { path: 'calendar/project/:projectId/line/:lineId', element: <Calendar /> },         
        { path: '', element: <Navigate to="/dashboard/grid" /> },        
        {
          path: 'contractors',
          children: [
            { path: 'list', element: <AccessBasedGuard accessList={["All","Settings.Contractors.List"]}><ContractorList /></AccessBasedGuard> },
            { path: 'new', element: <ContractorCreate /> },
            { path: ':id/edit', element: <ContractorCreate /> },
          ]
        },         
        {
          path: 'users',
          children: [
            { path: 'list', element: <AccessBasedGuard accessList={["All","Admin.Users.List"]}><UserList /></AccessBasedGuard> },
            { path: 'new', element: <UserCreate /> },
            { path: ':id/edit', element: <UserCreate /> },
          ]
        },        
        {
          path: 'roles',
          children: [
            { path: 'list', element: <AccessBasedGuard accessList={["All","Admin.Roles.List"]}><RoleList /></AccessBasedGuard> },
            { path: 'new', element: <RoleCreate /> },
            { path: ':id/edit', element: <RoleCreate /> },
          ]
        },
        {
          path: 'companies',
          children: [
            { path: 'list', element: <AccessBasedGuard accessList={["All","Admin.Companies.List"]}><CompanyList /></AccessBasedGuard> },
            { path: 'new', element: <CompanyCreate /> },
            { path: ':id/edit', element: <CompanyCreate /> },
          ]
        },
        {
          path: 'tasks',
          children: [
            { path: 'list', element: <AccessBasedGuard accessList={["All","Settings.Tasks.List"]}><TaskList /></AccessBasedGuard> },
            { path: 'new', element: <TaskCreate /> },
            { path: ':id/edit', element: <TaskCreate /> },
          ]
        },
        {
          path: 'task-groups',
          children: [
            { path: 'list', element: <AccessBasedGuard accessList={["All","Settings.TaskGroups.List"]}><TaskGroupList /></AccessBasedGuard> },
            { path: 'new', element: <TaskGroupCreate /> },
            { path: ':id/edit', element: <TaskGroupCreate /> },            
          ]
        },
        {
          path: 'projects',
          children: [
            { path: 'list', element: <AccessBasedGuard accessList={["All","Settings.Projects.List"]}><ProjectList /></AccessBasedGuard> },
            { path: 'new', element: <ProjectCreate /> },
            { path: ':id/edit', element: <ProjectCreate /> },                    
          ]
        }                             
      ]
    },  
    // { path: 'shared/projects/:shareId', element: <AccessBasedGuard accessList={["All","Projects.Share"]}><ProjectShare /></AccessBasedGuard> },
    {
      path: '/',
      element: (
        <GuestGuard>
          <Navigate to="auth/login" replace /> {/* <MainLayout/> */}
        </GuestGuard>
      )
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// Dashboard
const MainGrid = Loadable(lazy(() => import('../pages/dashboard/MainGrid')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const ProjectShare = Loadable(lazy(() => import('../pages/shared/ProjectShare')));

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const SetNewPassword = Loadable(lazy(() => import('../pages/authentication/SetNewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Settings
const RoleList = Loadable(lazy(() => import('../pages/settings/RoleList')));
const RoleCreate = Loadable(lazy(() => import('../pages/settings/RoleCreate')));
// Dashboard
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
/* const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile'))); */
/* const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards'))); */

const UserList = Loadable(lazy(() => import('../pages/settings/UserList')));
const UserCreate = Loadable(lazy(() => import('../pages/settings/UserCreate')));

const CompanyList = Loadable(lazy(() => import('../pages/settings/CompanyList')));
const CompanyCreate = Loadable(lazy(() => import('../pages/settings/CompanyCreate')));

const ContractorList = Loadable(lazy(() => import('../pages/settings/ContractorList')));
const ContractorCreate = Loadable(lazy(() => import('../pages/settings/ContractorCreate')));

const TaskList = Loadable(lazy(() => import('../pages/settings/TaskList')));
const TaskCreate = Loadable(lazy(() => import('../pages/settings/TaskCreate')));

const TaskGroupList = Loadable(lazy(() => import('../pages/settings/TaskGroupList')));
const TaskGroupCreate = Loadable(lazy(() => import('../pages/settings/TaskGroupCreate')));

const ProjectList = Loadable(lazy(() => import('../pages/settings/ProjectList')));
const ProjectCreate = Loadable(lazy(() => import('../pages/settings/ProjectCreate')));
