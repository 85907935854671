import PropTypes from "prop-types";
// material
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="96%" height="96%" viewBox="0 0 512 512" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(-12.000000,512.000000) scale(0.100000,-0.100000)" fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            d="M2440 5209 c-331 -31 -715 -149 -985 -301 -483 -274 -845 -649 -1084
-1125 -127 -251 -209 -523 -253 -828 -17 -119 -17 -523 0 -640 68 -472 229
-868 502 -1233 454 -607 1184 -999 1923 -1031 509 -22 928 70 1363 298 233
123 411 256 619 465 248 249 412 489 543 796 223 520 270 1085 138 1639 -13
53 -51 201 -86 329 l-63 232 -713 0 c-393 0 -714 2 -714 5 0 3 22 36 49 73
126 173 924 1308 928 1319 4 10 -207 13 -1038 12 -574 -1 -1082 -5 -1129 -10z
m1170 -1404 c0 -2 -219 -314 -486 -692 l-486 -688 607 -3 607 -2 -7 -33 c-47
-219 -173 -446 -335 -600 -234 -224 -500 -331 -820 -330 -455 2 -843 239
-1048 640 -178 347 -174 757 10 1093 86 156 236 324 377 419 130 89 333 168
486 190 61 9 1095 15 1095 6z"
          />
        </g>
      </svg>
    </Box>
  );
}
