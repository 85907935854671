import { Icon } from '@iconify/react';
import { PATH_DASHBOARD } from '../../routes/paths';
import SvgIconStyle from '../../components/SvgIconStyle';
// ----------------------------------------------------------------------

const getLocalIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const getIconifyIcon = (name) => (
  <Icon icon={name} idth='100%' height='100%'/>
);

const ICONS = {
  user: getIconifyIcon('mdi:account'),
  group: getIconifyIcon('mdi:account-group'),
  company: getIconifyIcon('mdi:domain'),
  contractors: getIconifyIcon('mdi:file-sign'),
  types: getIconifyIcon('mdi:format-list-bulleted-type'),
  activities: getIconifyIcon('mdi:calendar-check'),
  lanes: getIconifyIcon('mdi:file-tree'),
  projects: getIconifyIcon('mdi:chart-timeline'),  
  calendar: getLocalIcon('ic_calendar'),
  analytics: getLocalIcon('ic_analytics'),
  dashboard: getLocalIcon('ic_dashboard'),
};

const sidebarConfig = [

  // General
  {
    subheader: 'general',
    accessList: ["All","Projects.GetByUser"],
    items: [
      {
         title: 'grid', 
         path: PATH_DASHBOARD.general.grid,
         icon: ICONS.dashboard,    
         accessList: ["All","Projects.GetByUser"]             
      },      
/*       { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics }, */
/*       { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar } */
    ]
  },

  // Settings
  {
    subheader: 'settings',  
    accessList: ["All","Settings.Projects.List", "Settings.TaskGroups.List", "Settings.Tasks.List", "Settings.Contractors.List"],    
    items: [
      { 
        title: 'projects',
        path: PATH_DASHBOARD.projects.list,
        icon: ICONS.projects,
        accessList: ["All","Settings.Projects.List"]          
      },           
      { 
        title: 'task groups',
        path: PATH_DASHBOARD.taskGroups.list,
        icon: ICONS.lanes,
        accessList: ["All","Settings.TaskGroups.List"]        
      },   
      { 
        title: 'tasks', 
        path: PATH_DASHBOARD.tasks.list,
        icon: ICONS.activities,
        accessList: ["All","Settings.Tasks.List"]        
      },      
      { 
        title: 'contractors', 
        path: PATH_DASHBOARD.contractors.list, 
        icon: ICONS.contractors,
        accessList: ["All","Settings.Contractors.List"]        
      },       
    ]
  },  

  // Admin
  {
    subheader: 'Admin',
    accessList: ["All","Admin.Users.List", "Admin.Roles.List", "Admin.Companies.List"],    
    items: [
      {
        title: 'users',
        path: PATH_DASHBOARD.users.list,
        icon: ICONS.user,
        accessList: ["All","Admin.Users.List"]
      },      
      {
        title: 'roles',
        path: PATH_DASHBOARD.roles.list,
        icon: ICONS.group,
        accessList: ["All","Admin.Roles.List"]
      },
      {
        title: 'companies',
        path: PATH_DASHBOARD.companies.list,
        icon: ICONS.company,
        accessList: ["All","Admin.Companies.List"]
      },    
    ]
  }
];

export default sidebarConfig;
