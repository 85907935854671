import { filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { setError } from "./error";

const initialState = {
  isDataLoading: false,
  error: false,
  roleList: [],
  accessList: [],
  notifications: null,
};

const slice = createSlice({
  name: "role",
  initialState,
  reducers: {
    startLoading(state) {
      state.isDataLoading = true;
    },

    stopLoading(state) {
      state.isDataLoading = false;
    },

    hasError(state, action) {
      state.isDataLoading = false;
      state.error = action.payload;
    },

    deleteRole(state, action) {
      const list = filter(state.roleList, (item) => item.id !== action.payload);
      state.roleList = list;
      state.isDataLoading = false;
    },

    getRoleListSuccess(state, action) {
      state.isDataLoading = false;
      state.roleList = action.payload;
    },

    getRoleAccessListtSuccess(state, action) {
      state.isDataLoading = false;
      state.accessList = action.payload;
    },
  },
});

export default slice.reducer;

export function getRoleList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/admin.roles.list`);
      dispatch(slice.actions.getRoleListSuccess(response.data));
    } catch (error) {
      dispatch(setError({ error }));
      dispatch(slice.actions.stopLoading());
    }
  };
}

export function createEditRole(payload, isEdit) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = isEdit ? `${process.env.REACT_APP_API}/admin.roles.edit` : `${process.env.REACT_APP_API}/admin.roles.create`;
      await axios.post(url, payload);
      dispatch(slice.actions.startLoading(false));
    } catch (error) {
      dispatch(setError({ error }));
      dispatch(slice.actions.stopLoading());
    }
  };
}

export function deleteItem(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // await new Promise((resolve) => setTimeout(resolve, 500));
      await axios.post(`${process.env.REACT_APP_API}/admin.roles.delete`, { id });
      dispatch(slice.actions.deleteRole(id));
    } catch (error) {
      dispatch(setError({ error }));
      dispatch(slice.actions.stopLoading());
    }
  };
}

export function getRoleAccessList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/admin.roles.lookup`);
      dispatch(slice.actions.getRoleAccessListtSuccess(response.data));
    } catch (error) {
      dispatch(setError({ error }));
      dispatch(slice.actions.stopLoading());
    }
  };
}
