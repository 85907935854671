import axios from "axios";
import firebase from "firebase/compat/app";

// ----------------------------------------------------------------------

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.request.use(
  async (config) => {
    const { currentUser } = firebase.auth();
    if (currentUser) {
      const token = await currentUser.getIdToken();
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
