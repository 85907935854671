import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  errorDetails: null,
  message: "",
};

const slice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError(state, action) {
      state.errorDetails = action.payload;
    },
    clearErrors(state) {
      state.errorDetails = null;
    },
  },
});

export default slice.reducer;

export function setError(error) {
  console.error(error);
  return async (dispatch) => {
    dispatch(slice.actions.setError(error));
  };
}

export function clearErrors() {
  return async (dispatch) => {
    dispatch(slice.actions.clearErrors());
  };
}
