import { filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { setError } from "./error";

const initialState = {
  isDataLoading: false,
  error: false,
  taskGroupList: [],
  notifications: null,
};

const slice = createSlice({
  name: "taskGroup",
  initialState,
  reducers: {
    startLoading(state) {
      state.isDataLoading = true;
    },

    hasError(state, action) {
      state.isDataLoading = false;
      state.error = action.payload;
    },

    delete(state, action) {
      const list = filter(state.taskGroupList, (item) => item.id !== action.payload);
      state.taskGroupList = list;
      state.isDataLoading = false;
    },

    getListSuccess(state, action) {
      state.isDataLoading = false;
      state.taskGroupList = action.payload;
    },
  },
});

export default slice.reducer;

export function getTaskGroupList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/settings.taskGroups.list`);
      dispatch(slice.actions.getListSuccess(response.data));
    } catch (error) {
      dispatch(setError({ error }));
    }
  };
}

export function createEditItem(payload, isEdit) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = isEdit ? `${process.env.REACT_APP_API}/settings.taskGroups.edit` : `${process.env.REACT_APP_API}/settings.taskGroups.create`;
      await axios.post(url, payload);
      dispatch(slice.actions.startLoading(false));
    } catch (error) {
      dispatch(setError({ error }));
    }
  };
}

export function deleteItem(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`${process.env.REACT_APP_API}/settings.taskGroups.delete`, { id });
      dispatch(slice.actions.delete(id));
    } catch (error) {
      dispatch(setError({ error }));
    }
  };
}
