import { filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { setError } from "./error";

const initialState = {
  isDataLoading: false,
  error: false,
  userList: [],
  roleList: [],
  notifications: null,
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    startLoading(state) {
      state.isDataLoading = true;
    },

    hasError(state, action) {
      state.isDataLoading = false;
      state.error = action.payload;
    },

    deleteUser(state, action) {
      const list = filter(state.userList, (item) => item.id !== action.payload);
      state.userList = list;
      state.isDataLoading = false;
    },

    getUserListSuccess(state, action) {
      state.isDataLoading = false;
      state.userList = action.payload;
    },

    getRoleListSuccess(state, action) {
      state.isDataLoading = false;
      state.roleList = action.payload;
    },
  },
});

export default slice.reducer;

export function getUserList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/admin.users.list`);
      dispatch(slice.actions.getUserListSuccess(response.data));
    } catch (error) {
      dispatch(setError({ error }));
    }
  };
}

export function getRoleList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // TODO: create endpoint that return id and role name only , this one returns too much data
      const response = await axios.get(`${process.env.REACT_APP_API}/admin.roles.list`);
      dispatch(slice.actions.getRoleListSuccess(response.data));
    } catch (error) {
      dispatch(setError({ error }));
    }
  };
}

export function createEditUser(payload, isEdit) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = isEdit ? `${process.env.REACT_APP_API}/admin.users.edit` : `${process.env.REACT_APP_API}/admin.users.create`;
      await axios.post(url, payload);
      dispatch(slice.actions.startLoading(false));
    } catch (error) {
      dispatch(setError({ error }));
    }
  };
}

export function deleteItem(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // await new Promise((resolve) => setTimeout(resolve, 500));
      await axios.post(`${process.env.REACT_APP_API}/admin.users.delete`, { id });
      dispatch(slice.actions.deleteUser(id));
    } catch (error) {
      dispatch(setError({ error }));
    }
  };
}
