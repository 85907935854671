import { combineReducers } from 'redux';
/* import { persistReducer } from 'redux-persist'; */
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';
import roleReducer from './slices/role';
import companyReducer from './slices/company';
import contractorReducer from './slices/contractor';
import taskReducer from './slices/task';
import taskGroupReducer from './slices/taskGroup';
import projectReducer from './slices/project';
import commentReducer from './slices/comment';
import calendarReducer from './slices/calendar';
import errorReducer from './slices/error';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

/* const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const authPersistConfig = {
  key: 'auth',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
}; */

const rootReducer = combineReducers({
  user: userReducer,
  role: roleReducer,
  company: companyReducer,
  contractor: contractorReducer,
  task: taskReducer,
  taskGroup: taskGroupReducer,
  project: projectReducer,
  comment: commentReducer,
  calendar: calendarReducer,
  error: errorReducer
});

export { rootPersistConfig, rootReducer };
