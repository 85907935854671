import { filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  projectList: [],
  userProject: null,
  sharedProject: null,
  isDataLoading: false,
  isTaskLoading: false,
  isDraftProject: false,
  isError: false,
  error: "",
};

const slice = createSlice({
  name: "project",
  initialState,
  reducers: {
    startLoading(state, action) {
      state.isDataLoading = true;
    },

    stopLoading(state, action) {
      state.isDataLoading = false;
    },

    startTaskLoading(state, action) {
      state.isTaskLoading = true;
    },

    stopTaskLoading(state, action) {
      state.isTaskLoading = false;
    },

    hasError(state, action) {
      state.isError = true;
      state.error = action.payload;
      state.isDataLoading = false;
      state.isTaskLoading = false;
    },

    delete(state, action) {
      const list = filter(state.projectList, (item) => item.id !== action.payload);
      state.projectList = list;
      state.isDataLoading = false;
    },

    getListSuccess(state, action) {
      state.projectList = action.payload;
      state.isDataLoading = false;
    },

    getUserProjectSuccess(state, action) {
      state.userProject = action.payload;
      state.isDraftProject = action.payload.selectedProject.status === 10; // TODO: does it belong here?
      state.isDataLoading = false;
    },

    updateProjectLine(state, action) {
      const project = state.userProject;
      const { line } = action.payload;
      const index = project.selectedProject.lines.findIndex((x) => x.id === line.id);
      project.selectedProject.lines[index] = line;
      state.userProject = project;
    },

    updateProjectShareId(state, action) {
      const project = state.userProject;
      const { shareId } = action.payload;
      project.selectedProject.shareId = shareId;
      state.userProject = project;
    },
    getSharedProjectSuccess(state, action) {
      state.sharedProject = action.payload?.project;
    },

    startProjectSuccess(state, action) {
      state.isDraftProject = false;
    },
    resetProjects(state, action) {
      state.userProject = null;
      state.isDraftProject = null;
      state.projectList = [];
    },
  },
});

export default slice.reducer;

export function startProject(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `${process.env.REACT_APP_API}/projects.start`;
      await axios.post(url, { id });
      dispatch(slice.actions.stopLoading());
      dispatch(slice.actions.startProjectSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function shareProject(id) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const url = `${process.env.REACT_APP_API}/projects.share`;
      const response = await axios.post(url, { id });
      return Promise.resolve(response.data); // TODO: not sure if this is a good practice
      // dispatch(slice.actions.updateProjectShareId(response.data));
    } catch (error) {
      return Promise.reject(error); // TODO: not sure if this is a good practice
    }
  };
}

export function getSharedProject(shareId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `${process.env.REACT_APP_API}/projects.view?shareId=${shareId}`;
      const response = await axios.get(url);
      dispatch(slice.actions.getSharedProjectSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveFavoriteProject(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `${process.env.REACT_APP_API}/projects.favorite`;
      await axios.post(url, { id });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Unused but keep it here for PDF export
export function exportProject(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `${process.env.REACT_APP_API}/projects.export?id=${id}`;
      await axios.get(url, { responseType: "blob" }).then((response) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.setAttribute("download", `${id}.pdf`);
        document.body.appendChild(link);
        link.click();

        dispatch(slice.actions.stopLoading());
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserProject(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading(true));
    try {
      const response = id ? await axios.get(`${process.env.REACT_APP_API}/projects.get?id=${id}`) : await axios.get(`${process.env.REACT_APP_API}/projects.get`);
      dispatch(slice.actions.getUserProjectSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProjectList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/settings.projects.list`);
      dispatch(slice.actions.getListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createEditItem(payload, isEdit) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = isEdit ? `${process.env.REACT_APP_API}/settings.projects.edit` : `${process.env.REACT_APP_API}/settings.projects.create`;
      await axios.post(url, payload);
      dispatch(slice.actions.stopLoading());
      return Promise.resolve(); // TODO: not sure if this is a good practice
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return Promise.reject(); // TODO: not sure if this is a good practice
    }
  };
}

export function deleteItem(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`${process.env.REACT_APP_API}/settings.projects.delete`, { id });
      dispatch(slice.actions.delete(id));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editProjectTask(payload) {
  return async (dispatch) => {
    // dispatch(slice.actions.startTaskLoading());
    try {
      const url = `${process.env.REACT_APP_API}/projects.tasks.edit${payload.dateType}`;
      const response = await axios.post(url, payload);
      dispatch(slice.actions.updateProjectLine(response.data));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
      // dispatch(slice.actions.hasError(error));
    }
  };
}

export function restoreProjectTask(payload) {
  return async (dispatch) => {
    // dispatch(slice.actions.startTaskLoading());
    try {
      const url = `${process.env.REACT_APP_API}/projects.tasks.restore`;
      const response = await axios.post(url, payload);
      dispatch(slice.actions.updateProjectLine(response.data));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
      // dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetProjects(id) {
  return async (dispatch) => {
    dispatch(slice.actions.resetProjects());
  };
}
