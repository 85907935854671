// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
// const ROOTS_SETTINGS = '/settings';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  setNewPassword: path(ROOTS_AUTH, '/set-new-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  users: {
    root: path(ROOTS_DASHBOARD, '/users'),    
    list: path(ROOTS_DASHBOARD, '/users/list'),
    new: path(ROOTS_DASHBOARD, '/users/new'),
    edit: path(ROOTS_DASHBOARD, `/users/:id/edit`),
  },    
  roles: {
    root: path(ROOTS_DASHBOARD, '/roles'),    
    list: path(ROOTS_DASHBOARD, '/roles/list'),
    new: path(ROOTS_DASHBOARD, '/roles/new'),
    edit: path(ROOTS_DASHBOARD, `/roles/:id/edit`),
  },
  companies: {
    root: path(ROOTS_DASHBOARD, '/companies'),    
    list: path(ROOTS_DASHBOARD, '/companies/list'),
    new: path(ROOTS_DASHBOARD, '/companies/new'),
    edit: path(ROOTS_DASHBOARD, `/companies/:id/edit`)
  },
  contractors: {
    root: path(ROOTS_DASHBOARD, '/contractors'),    
    list: path(ROOTS_DASHBOARD, '/contractors/list'),
    new: path(ROOTS_DASHBOARD, '/contractors/new'),
    edit: path(ROOTS_DASHBOARD, `/contractors/:id/edit`)
  },
  tasks: {
    root: path(ROOTS_DASHBOARD, '/tasks'),    
    list: path(ROOTS_DASHBOARD, '/tasks/list'),
    new: path(ROOTS_DASHBOARD, '/tasks/new'),
    edit: path(ROOTS_DASHBOARD, `/tasks/:id/edit`)
  },
  taskGroups: {
    root: path(ROOTS_DASHBOARD, '/task-groups'),    
    list: path(ROOTS_DASHBOARD, '/task-groups/list'),
    new: path(ROOTS_DASHBOARD, '/task-groups/new'),
    edit: path(ROOTS_DASHBOARD, `/task-groups/:id/edit`)
  }, 
  projects: {
    root: path(ROOTS_DASHBOARD, '/projects'),    
    list: path(ROOTS_DASHBOARD, '/projects/list'),
    new: path(ROOTS_DASHBOARD, '/projects/new'),
    edit: path(ROOTS_DASHBOARD, `/projects/:id/edit`)
  },      
  general: {
    grid: path(ROOTS_DASHBOARD, '/grid'), 
    gridEdit: path(ROOTS_DASHBOARD, '/grid/:id?'),     
    analytics: path(ROOTS_DASHBOARD, '/analytics')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar/project/:projectId/line/:lineId'),
};

export const PATH_DOCS = 'https://isready.io';
