import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
// import { setError } from './error';

const initialState = {
  comments: [],
  isLoadingComments: false,
  // // isSuccess: true,
  // // error: ""
};

const slice = createSlice({
  name: "comment",
  initialState,
  reducers: {
    startLoading(state, action) {
      state.isLoadingComments = true;
    },
    stopLoading(state, action) {
      state.isLoadingComments = false;
    },
    // hasError(state, action) {
    //   state.isSuccess = false;
    //   state.isDataLoading = false;
    //   state.error = action.payload;
    // },

    getCommentsSuccess(state, action) {
      state.comments = action.payload;
    },

    resetComments(state, action) {
      state.comments = [];
    },

    updateCommentsSuccess(state, action) {
      const comment = action.payload;
      const existingComments = state.comments;
      const comments = existingComments.unshift(comment);
    },
  },
});

export default slice.reducer;

export function getComments(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/projects.tasks.getComments?id=${id}`);
      dispatch(slice.actions.getCommentsSuccess(response.data));
      dispatch(slice.actions.stopLoading());
      return Promise.resolve();
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      return Promise.reject(error);
      // dispatch(slice.actions.hasError(error));
      // dispatch(setError({error}));
    }
  };
}

export function createComment(payload) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${process.env.REACT_APP_API}/projects.tasks.createComment`, payload);
      dispatch(slice.actions.updateCommentsSuccess(response.data));
      // dispatch(slice.actions.stopLoading());
      return Promise.resolve();
    } catch (error) {
      // dispatch(slice.actions.stopLoading());
      return Promise.reject(error);
      // dispatch(slice.actions.hasError(error));
      // dispatch(setError({error}));
    }
  };
}

export function resetComments() {
  return async (dispatch) => {
    dispatch(slice.actions.resetComments());
  };
}
