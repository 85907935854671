import { filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { setError } from "./error";

const initialState = {
  isDataLoading: false,
  companyList: [],
  notifications: null,
};

const slice = createSlice({
  name: "company",
  initialState,
  reducers: {
    startLoading(state) {
      state.isDataLoading = true;
    },

    stopLoading(state) {
      state.isDataLoading = false;
    },

    delete(state, action) {
      const list = filter(state.companyList, (item) => item.id !== action.payload);
      state.companyList = list;
      state.isDataLoading = false;
    },

    getListSuccess(state, action) {
      state.isDataLoading = false;
      state.companyList = action.payload;
    },
  },
});

export default slice.reducer;

export function getCompanyList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/admin.companies.list`);
      dispatch(slice.actions.getListSuccess(response.data));
    } catch (error) {
      dispatch(setError({ error }));
      dispatch(slice.actions.stopLoading());
    }
  };
}

export function switchCompany(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`${process.env.REACT_APP_API}/admin.companies.switch`, { id });
      dispatch(slice.actions.startLoading(false));
    } catch (error) {
      dispatch(setError({ error }));
      dispatch(slice.actions.stopLoading());
    }
  };
}

export function createEditItem(payload, isEdit) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = isEdit ? `${process.env.REACT_APP_API}/admin.companies.edit` : `${process.env.REACT_APP_API}/admin.companies.create`;
      await axios.post(url, payload);
      dispatch(slice.actions.startLoading(false));
    } catch (error) {
      dispatch(setError({ error }));
      dispatch(slice.actions.stopLoading());
    }
  };
}

export function deleteItem(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`${process.env.REACT_APP_API}/admin.companies.delete`, { id });
      dispatch(slice.actions.delete(id));
    } catch (error) {
      dispatch(setError({ error }));
      dispatch(slice.actions.stopLoading());
    }
  };
}
