import PropTypes from 'prop-types';
import { Container, Alert, AlertTitle } from '@mui/material';
import useAuth from '../hooks/useAuth';
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

AccessBasedGuard.propTypes = {
  accessList: PropTypes.array,
  children: PropTypes.node
};

// TODO: maybe use UseHandleListAccess
const useUserAccessList = () => {
  const { user } = useAuth();
  return user.accessList;
};

const checker = (arr1, arr2) => arr1.some(r=> arr2.includes(r));

export default function AccessBasedGuard({ accessList, children }) {
  const userAccessList = useUserAccessList();
  const hasAccess = checker(accessList, userAccessList);

  if (!userAccessList) {
    return <LoadingScreen /> ;
  }

  if (hasAccess) {
    return <>{children}</>;
  }

  return (
    <Container>
      <Alert severity="error">
        <AlertTitle>Permission Denied</AlertTitle>
        You do not have permission to access this page
      </Alert>
    </Container>
  );
}
